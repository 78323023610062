// import { svgSprite } from './components/svg-sprite';
// import DetailsUtils from '@zachleat/details-utils';
import { externalLinks } from './components/external-links';
import { generalFunctions } from './components/general-functions';

generalFunctions();
// svgSprite();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  externalLinks();
  // DetailsUtils();
});
