export function externalLinks() {
  let i = 0;
  const container = document.querySelector('main');
  const anchors = container.querySelectorAll('a');
  for (i; i < anchors.length; i++) {
    if (anchors[i].hostname !== window.location.hostname) {
      anchors[i].setAttribute('target', '_blank');
      anchors[i].setAttribute('rel', 'noreferrer');
    }
  }
}
